import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import LoggedInUserContext from '../../context/LoggedInUserContext';

import UserContext from '../../context/UserContext';
import useComponentMounted from '../../hooks/useComponentMounted';
import useCurrentLoggedInUser from '../../hooks/useCurrentLoggedInUser';
import useSessionStore from '../../hooks/useSessionStore';
import Coach from '../../models/Coach';

import { getQueryVariable } from '../../utils/queryParams';
import useAppCustomization from '../../hooks/useAppCustomization';
import { Feature } from '../../context/AppCustomizationContext';

const useChat = () => {
  const { isCoachOrAdmin } = useSessionStore();
  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();
  const [isUserInPathCoach, setUserInPathCoach] = useState(false);

  const {
    userDoc: userInRouteDoc,
  } = useContext(UserContext);
  const { userDoc: userLoggedInDoc } = useContext(LoggedInUserContext);

  const { isFeatureEnabled } = useAppCustomization();

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const initUserInPathCoach = async () => {
      if (isCoachOrAdmin) {
        const isCoachUser = await Coach.isCoach(userInRouteDoc.id);
        if (isComponentMountedRef.current) {
          setUserInPathCoach(isCoachUser);
        }
      } else {
        setUserInPathCoach(false);
      }
    };

    initUserInPathCoach();
  }, [
    isCoachOrAdmin,
    isComponentMountedRef,
    userInRouteDoc.id,
  ]);

  const showUserChat = getQueryVariable('show_user_chat') === 'true';

  const userDocForChat = (isCurrentLoggedInUserInPath || showUserChat)
    ? userInRouteDoc
    : userLoggedInDoc;

  const streamToken = userDocForChat?.streamToken;

  const isChatEnabled = useMemo(() => (
    isFeatureEnabled(Feature.CHAT) && !!streamToken
  ), [
    isFeatureEnabled,
    streamToken,
  ]);

  const multiChannelMode = useMemo(() => (
    showUserChat ? isUserInPathCoach : isCoachOrAdmin
  ), [isUserInPathCoach, showUserChat, isCoachOrAdmin]);

  const initialViewAsChannelConfig = useMemo(() => ((!showUserChat && !isCurrentLoggedInUserInPath) ? {
    channel: userInRouteDoc.id,
    members: [userInRouteDoc.id, userLoggedInDoc.id],
  } : null), [
    isCurrentLoggedInUserInPath,
    showUserChat,
    userInRouteDoc.id,
    userLoggedInDoc.id,
  ]);

  return useMemo(() => ({
    isChatEnabled,
    userDocForChat,
    multiChannelMode,
    readOnlyMode: showUserChat,
    initialViewAsChannelConfig,
  }), [
    isChatEnabled,
    userDocForChat,
    showUserChat,
    multiChannelMode,
    initialViewAsChannelConfig,
  ]);
};

export default useChat;
