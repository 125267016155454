const CHANNEL_QUERY_LIMIT = 30;

const getAllChannels = async (client, coachId, userIds, offset = 0, channelList = []) => {
  const newChannelList = await client.queryChannels({
    type: 'messaging',
    members: { $in: [coachId] },
    $or: [{ hidden: true }, { hidden: false }],
  }, {}, { limit: CHANNEL_QUERY_LIMIT, offset });
  const tempOffset = offset + CHANNEL_QUERY_LIMIT;
  channelList.push(...newChannelList);
  if (newChannelList.length !== 0) {
    await getAllChannels(client, coachId, userIds, tempOffset, channelList);
  }
  const filteredChannelList = channelList.filter((channel) => userIds.includes(channel.id));
  return filteredChannelList;
};

export {
  getAllChannels,
};
