import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StreamChat } from 'stream-chat';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import useComponentMounted from '../../hooks/useComponentMounted';
import BroadcastMessage from '../../models/BroadcastMessage';
import config from '../../config';
import useChat from '../hooks/useChat';
import { ChannelBuckets } from '../util/buckets';
import ChatContext from './ChatContext';
import ChatState from './states';

const STREAM_TIMEOUT = 5000;

const ChatContextProvider = ({
  children,
}) => {
  const [chatClient, setChatClient] = useState(null);
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const [chatState, setChatState] = useState(ChatState.CHAT_NOT_INITIALIZED);
  const [isChatModalOpen, setChatModalOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedBucket, setSelectedBucket] = useState(ChannelBuckets.ACTIVE);
  const chatMenuRef = useRef();

  const {
    readOnlyMode,
    multiChannelMode,
    userDocForChat: {
      id: userId,
      streamToken,
      firstName,
      avatarUrl = '',
      flags: userFlags,
    },
    initialViewAsChannelConfig,
  } = useChat();

  const [customActiveChannel, setCustomActiveChannel] = useState(null);
  const [initialChatMenuOpen, setInitialChatMenuOpen] = useState(false);
  const [isBroadcastOpen, setIsBroadcastOpen] = useState(false);
  const isComponentMountedRef = useComponentMounted();

  /**
   * It will make the state of the chat to be erroneous of any provided Stream error.
   * This will enable a refresh button so that the whole chat can be re-initialized.
   */
  const onChatError = useCallback(() => {
    setChatState(ChatState.CHAT_ERROR);
  }, []);

  /**
   * It makes the chat to transition again to the NOT_INITIALIZED state so the whole
   * initialization occurs again
   */
  const onChatRefresh = useCallback(() => {
    setChatState(ChatState.CHAT_NOT_INITIALIZED);
  }, []);

  /**
   * It toggles chat dialog visibility
   */
  const toggleChatModal = useCallback(() => {
    setChatModalOpen((currentChatDialogState) => !currentChatDialogState);
  }, []);

  /**
   * Opens Chat Modal.
   *
   * - If the chat is already opened, it won't do anything.
   * - For multi-channel view, it will set the custom active channel to be the one from the notification
   * - It will skip the chat list opening if it's the first time the chat gets rendered and when coming from a
   *   notification.
   */
  const openChatModal = useCallback((channelId) => {
    if (channelId && channelId !== customActiveChannel) {
      setCustomActiveChannel(channelId);
    }

    if (!isChatModalOpen) {
      setChatModalOpen(true);
    }

    if (!initialChatMenuOpen) {
      setInitialChatMenuOpen(true);
    }
  }, [
    initialChatMenuOpen,
    customActiveChannel,
    isChatModalOpen,
  ]);

  const shouldCheckForGreetingMessage = useMemo(() => !readOnlyMode
    && userFlags.dayZero
    && userFlags.greetingMessageSent
    && !multiChannelMode
    && chatState === ChatState.CHAT_INITIALIZED
    && totalUnreadCount === 1,
  [
    chatState,
    multiChannelMode,
    totalUnreadCount,
    userFlags,
    readOnlyMode,
  ]);

  /*
    Opens the chat if the user is dayZero and has received the greeting message from the coach
  */
  const showGreetingMessageIfAny = useCallback(async () => {
    const channels = await chatClient.queryChannels({ id: { $eq: userId } });
    if (channels.length === 0) {
      Sentry.captureException(
        new Error(`The user ${userId} doesn't have a channel`),
        {
          extra: {
            userId,
          },
        },
      );
      return;
    }
    const channel = await channels[0].query();
    const { messages } = channel;
    if (!messages || messages.length === 0) {
      return;
    }
    const message = messages[messages.length - 1];
    if (message && message.isGreetingMessage) {
      openChatModal();
    }
  }, [
    chatClient,
    userId,
    openChatModal,
  ]);

  /**
   *  This functions initialized the chat by getting the chat client instance and the number of initial unread
   *  counts.
   */
  const initializeChat = useCallback(async () => {
    let initData = {};

    if (!streamToken) {
      Sentry.captureException(
        new Error(`User ${userId} is not registered with Stream`),
        {
          extra: { userId },
        },
      );
      return initData;
    }

    try {
      const streamChatClient = StreamChat.getInstance(config.streamIO.apiKey, {
        timeout: STREAM_TIMEOUT,
      });
      const initResponse = await streamChatClient.connectUser(
        {
          id: userId,
          name: firstName,
          image: avatarUrl,
        },
        streamToken,
      );

      initData = {
        chatClient: streamChatClient,
        // eslint-disable-next-line camelcase
        unreadCount: initResponse?.me?.total_unread_count || 0,
      };
    } catch (err) {
      Sentry.captureException(err, {
        extra: { userId },
      });
    }

    return initData;
  },
  [
    userId,
    firstName,
    avatarUrl,
    streamToken,
  ]);

  /**
   * Forces current user to be disconnected from chat
   */
  const disconnectUser = useCallback(async () => {
    if (chatClient) {
      await chatClient.disconnectUser(STREAM_TIMEOUT);
    }
  }, [
    chatClient,
  ]);

  useEffect(() => {
    const initChat = async () => {
      const {
        chatClient: streamChatClient,
        unreadCount,
      } = await initializeChat();

      if (isComponentMountedRef.current) {
        setTotalUnreadCount(unreadCount);

        if (!streamChatClient) {
          // An error ocurred during initialization
          setChatClient(null);
          setChatState(ChatState.CHAT_ERROR);
          return;
        }

        setChatClient(streamChatClient);
        setChatState(ChatState.CHAT_INITIALIZED);
      }
    };

    if (chatState === ChatState.CHAT_NOT_INITIALIZED) {
      // Set to initializing to guaranteed this is called only once
      setChatState(ChatState.CHAT_INITIALIZING);
      initChat();
    }
  }, [
    chatState,
    initializeChat,
    isComponentMountedRef,
  ]);

  /**
   * Whenever a new chatClient gets calculated we subscribe for event for unread count update.
   */
  useEffect(() => {
    let clientEventListenerUnsubscriptionHandler;
    if (chatClient) {
      clientEventListenerUnsubscriptionHandler = chatClient.on((event) => {
        const { total_unread_count: eventTotalUnreadCount } = event;
        if (typeof eventTotalUnreadCount === 'number') {
          setTotalUnreadCount(eventTotalUnreadCount);
        }
      });
    }

    return () => {
      if (clientEventListenerUnsubscriptionHandler) {
        clientEventListenerUnsubscriptionHandler.unsubscribe();
      }
    };
  }, [
    chatClient,
    userId,
  ]);

  useEffect(() => {
    const configureInitialViewAsChannel = async (client, channelConfig) => {
      const {
        channel: channelId,
        members: initialChannelMembers,
      } = channelConfig;

      const channels = await client.queryChannels({
        $and: initialChannelMembers.map((initialChannelMember) => ({
          members: { $in: [initialChannelMember] },
        })),
      });

      if (channels.length > 0) {
        const isInitialChannelValid = channels.some((channel) => channel.id === channelId);

        if (isInitialChannelValid && isComponentMountedRef.current) {
          setCustomActiveChannel(channelId);
        }
      }
    };

    if (chatClient && initialViewAsChannelConfig) {
      configureInitialViewAsChannel(chatClient, initialViewAsChannelConfig);
    }
  }, [
    readOnlyMode,
    chatClient,
    initialViewAsChannelConfig,
    isComponentMountedRef,
  ]);

  // The chat is considered to be ready when it's in one of the final states, either initialized or in error state.
  const isChatReady = useMemo(() => chatState === ChatState.CHAT_INITIALIZED || chatState === ChatState.CHAT_ERROR, [
    chatState,
  ]);

  const onInitialChatMenuOpened = useCallback(() => {
    if (isComponentMountedRef.current) {
      setInitialChatMenuOpen(true);
    }
  }, [
    isComponentMountedRef,
  ]);

  const onChannelSelected = useCallback((channelId) => {
    if (isComponentMountedRef.current) {
      setCustomActiveChannel(channelId);
    }
  }, [
    isComponentMountedRef,
  ]);

  const onMarkChannelAsRead = useCallback(async (channel) => {
    if (!readOnlyMode) {
      await channel.markRead();
    }
  }, [readOnlyMode]);

  const onSearch = useCallback((query) => {
    if (isComponentMountedRef.current) {
      setIsSearching(query !== '');
      setSearchQuery(query);
    }
  }, [
    isComponentMountedRef,
  ]);

  const onBucketSelected = useCallback((bucket) => {
    if (isComponentMountedRef.current) {
      setSelectedBucket(bucket);
    }
  }, [
    isComponentMountedRef,
  ]);

  const toggleChatMenu = useCallback(async () => {
    await chatMenuRef.current?.toggle();
  }, [
    chatMenuRef,
  ]);

  const openChatMenu = useCallback(async () => {
    await chatMenuRef.current?.open();
  }, [
    chatMenuRef,
  ]);

  // sends a broadcast message to list of users
  const sendBroadcastMessage = useCallback(async (recipients, message, additionalFields = {}) => {
    const recipientsArray = recipients.map(({ id, isActive, userDoc }) => ({
      id,
      isActive,
      firstName: userDoc.firstName,
    }));
    await BroadcastMessage.create({
      recipients: recipientsArray,
      message,
      coach: userId,
      ...additionalFields,
    });
  }, [
    userId,
  ]);

  const context = useMemo(() => ({
    userId,
    readOnlyMode,
    isMultiChannelView: multiChannelMode,
    isChatModalOpen,
    isChatReady,
    toggleChatModal,
    chatClient,
    totalUnreadCount,
    setTotalUnreadCount,
    onChatRefresh,
    onChatError,
    chatState,
    initialChatMenuOpen,
    onInitialChatMenuOpened,
    customActiveChannel,
    onChannelSelected,
    disconnectUser,
    openChatModal,
    onMarkChannelAsRead,
    showGreetingMessageIfAny,
    isSearching,
    onSearch,
    searchQuery,
    shouldCheckForGreetingMessage,
    selectedBucket,
    onBucketSelected,
    chatMenuRef,
    toggleChatMenu,
    openChatMenu,
    isBroadcastOpen,
    setIsBroadcastOpen,
    sendBroadcastMessage,
  }), [
    userId,
    readOnlyMode,
    multiChannelMode,
    isChatModalOpen,
    isChatReady,
    toggleChatModal,
    chatClient,
    totalUnreadCount,
    setTotalUnreadCount,
    onChatRefresh,
    onChatError,
    chatState,
    initialChatMenuOpen,
    onInitialChatMenuOpened,
    customActiveChannel,
    onChannelSelected,
    disconnectUser,
    openChatModal,
    onMarkChannelAsRead,
    showGreetingMessageIfAny,
    isSearching,
    onSearch,
    searchQuery,
    shouldCheckForGreetingMessage,
    selectedBucket,
    onBucketSelected,
    chatMenuRef,
    toggleChatMenu,
    openChatMenu,
    isBroadcastOpen,
    setIsBroadcastOpen,
    sendBroadcastMessage,
  ]);

  return (
    <ChatContext.Provider value={context}>
      {children}
    </ChatContext.Provider>
  );
};

ChatContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChatContextProvider;
