import React, { useCallback, useContext, useMemo } from 'react';
import { PropTypes } from 'mobx-react';
import moment from 'moment';

import InAppBrowserContext from '../../../../context/InAppBrowserContext';

import { DateFormat } from '../../../../utils/date';
import { InvoiceSectionContainer } from './styles';
import InvoiceListItem from './InvoiceListItem';
import { invoiceStatusOptions } from './utils';

const InvoicesList = ({ invoices }) => {
  const { open } = useContext(InAppBrowserContext);

  const openInvoiceDetails = useCallback((invoiceURL) => (
    open(invoiceURL, { toolbar: 'yes', footer: 'yes' })
  ), [open]);

  const invoiceArray = useMemo(() => (
    invoices.map((invoice) => {
      const invoiceStatus = invoice.status.toUpperCase().trim();

      let invoiceDate;
      if (invoiceStatus === invoiceStatusOptions.PAID) {
        invoiceDate = moment.unix(invoice.status_transitions.paid_at)
          .format(DateFormat.ABBREVIATED_MONTH_WITH_DATE_YEAR);
      } else {
        invoiceDate = moment.unix(invoice.created)
          .format(DateFormat.ABBREVIATED_MONTH_WITH_DATE_YEAR);
      }

      const invoiceId = invoice.id;
      const invoiceAmount = (invoice.amount_due / 100).toFixed(2);
      const invoiceCurrency = invoice.currency.toUpperCase();
      const invoiceURL = invoice.hosted_invoice_url;
      return {
        invoiceId,
        invoiceAmount,
        invoiceDate,
        invoiceStatus,
        invoiceCurrency,
        invoiceURL,
      };
    })
  ), [
    invoices,
  ]);

  const listInvoices = invoiceArray.map((inv) => (
    <InvoiceListItem {...inv} key={inv.invoiceId} handleClick={() => openInvoiceDetails(inv.invoiceURL)} />
  ));

  return <InvoiceSectionContainer>{listInvoices}</InvoiceSectionContainer>;
};

InvoicesList.propTypes = {
  invoices: PropTypes.arrayOrObservableArray,
};

InvoicesList.defaultProps = {
  invoices: [],
};

export default InvoicesList;
